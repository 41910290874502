import { Fragment, useState } from 'react';
import { Button } from 'antd';
import FormMagnetType from './FormMagnetType';
import { useMagnetTypes } from '../../../lib/hooks/use-magnet-types';
import { MagnetType } from '../../../models/Entities';
import List from './List';
import { MagnetTypeFormValues } from '../../../models/Dtos';


function MagnetTypePage() {
  const { loading, magnetTypes, createMagnetType, updateMagnetType, mutate } = useMagnetTypes();

  // Form MagnetType
  const [isMagnetTypeFormNew, setIsMagnetTypeFormNew] = useState(false);
  const [formMagnetTypeModalVisible, setFormMagnetTypeModalVisible] = useState(false);
  const [formMagnetTypeInitialValues, setFormMagnetTypeInitialValues] = useState<MagnetTypeFormValues>({
    description: "",
    isPublic: false,
    massFlowRate: 0,
    pressureDropSpec: 0,
    name: "",
    ordering: 0
  });

  const addMagnetType = () => {
    setIsMagnetTypeFormNew(true);
    setFormMagnetTypeInitialValues({
      description: "",
      isPublic: false,
      massFlowRate: 0,
      pressureDropSpec: 0,
      name: "",
      ordering: 0
    })
    setFormMagnetTypeModalVisible(true);
  }
  const handleEditMagnetType = (record: MagnetType): void => {
    const initialValues = { ...record };
    setFormMagnetTypeInitialValues(initialValues)
    setIsMagnetTypeFormNew(false);
    setFormMagnetTypeModalVisible(true);
  }
  const handleMagnetTypeModalClose = () => {
    setFormMagnetTypeModalVisible(false);
    mutate();
  }

  return (
    <Fragment>
      <div style={{marginBottom: '16px'}}>
        <Button type="primary" onClick={addMagnetType} >
          Add magnetType
        </Button>
      </div>
      <List
        magnetTypes={magnetTypes}
        isLoading={loading}
        handleEditMagnetType={handleEditMagnetType}
      />
      <FormMagnetType
        visible={formMagnetTypeModalVisible}
        onClose={handleMagnetTypeModalClose}
        isNew={isMagnetTypeFormNew}
        initialValues={formMagnetTypeInitialValues}
        onCreateMagnetType={createMagnetType}
        onUpdateMagnetType={updateMagnetType}
      />
    </Fragment>
  );
}

export default MagnetTypePage;

