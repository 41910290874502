import { Fragment, useState } from 'react';
import { Button } from 'antd';
import FormTubeType from './Form';
import { useTubeTypes } from '../../../lib/hooks/use-tube-types';
import { TubeType } from '../../../models/Entities';
import List from './List';
import { TubeTypeFormValues } from '../../../models/Dtos';


function TubeTypePage() {
  const { loading, tubeTypes, createTubeType, updateTubeType, mutate } = useTubeTypes();

  // Form TubeType
  const [isTubeTypeFormNew, setIsTubeTypeFormNew] = useState(false);
  const [formTubeTypeModalVisible, setFormTubeTypeModalVisible] = useState(false);
  const [formTubeTypeInitialValues, setFormTubeTypeInitialValues] = useState<TubeTypeFormValues>({
    icon: "",
    isPublic: false,
    ak: 0,
    nb: 0,
    lengthFactor: null,
    name: "",
    ordering: 0
  });

  const addTubeType = () => {
    setIsTubeTypeFormNew(true);
    setFormTubeTypeInitialValues({
      icon: "",
      isPublic: false,
      ak: 0,
      nb: 0,
      lengthFactor: null,
      name: "",
      ordering: 0
    })
    setFormTubeTypeModalVisible(true);
  }
  const handleEditTubeType = (record: TubeType): void => {
    const initialValues = { ...record };
    setFormTubeTypeInitialValues(initialValues)
    setIsTubeTypeFormNew(false);
    setFormTubeTypeModalVisible(true);
  }
  const handleTubeTypeModalClose = () => {
    setFormTubeTypeModalVisible(false);
    mutate();
  }

  return (
    <Fragment>
      <div style={{ marginBottom: '16px' }}>
        <Button type="primary" onClick={addTubeType} >
          Add Tube Type
        </Button>
      </div>
      <List
        tubeTypes={tubeTypes}
        isLoading={loading}
        handleEditTubeType={handleEditTubeType}
      />
      <FormTubeType
        visible={formTubeTypeModalVisible}
        onClose={handleTubeTypeModalClose}
        isNew={isTubeTypeFormNew}
        initialValues={formTubeTypeInitialValues}
        onCreateTubeType={createTubeType}
        onUpdateTubeType={updateTubeType}
      />
    </Fragment>
  );
}

export default TubeTypePage;

