import { useEffect, useReducer } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  message,
  Progress,
  Row,
  Spin,
} from 'antd';
import MagnetSetting from './MagnetSetting';
import reducer, { CalculatorActionType, CalculatorState } from './reducer';
import QLAlgorithm from '../../utils/QLAlgorithm';
import Result from './Result';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTubeTypes } from '../../lib/hooks/use-tube-types';
import { each } from 'lodash';
import { TubeItem } from '../../models/Entities';
import TubeItemTable from './TubeItemTable';
import { useTubeDiameters } from '../../lib/hooks/use-tube-diameters';
import { useDesignApi } from '../../lib/hooks/use-design-api';
import { useDesign } from '../../lib/hooks/use-design';
import { useMagnetTypes } from '../../lib/hooks/use-magnet-types';
import { defaultDecouplerTubeType, TubeItemTableRow, TubesAndResult } from './models';
import { calculateVibrationDecouplerData } from './calculator-helper';
import { BlockLoading } from '../../components/Loading';



function CreateDesignPage() {
  const { loading: isMagnetTypesLoading, magnetTypes } = useMagnetTypes();
  const initialState: CalculatorState = {
    magnetTypeId: undefined,
    tubeItems: [],
    designName: '',
    designId: null,
    createdAt: null,
    updatedAt: null,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const history = useHistory();
  const { id, mode } = useParams<{ id?: string, mode?: string }>();
  const { design, mutate: mutateDesign } = useDesign(id);

  const { createDesign, updateDesign } = useDesignApi();
  const handleSubmitClick = async () => {
    if (state.designId !== null) {
      try {
        const patches = [];
        if (design?.name !== state.designName)
          patches.push({ "op": "replace", "path": "/Name", "value": state.designName });
        if (design?.magnetTypeId !== state.magnetTypeId)
          patches.push({ "op": "replace", "path": "/MagnetTypeId", "value": state.magnetTypeId });
        patches.push({
          "op": "replace",
          "path": "/TubeItems",
          "value": state.tubeItems.map((item, index) =>
            ({ ...item, ordering: index }))
        });

        await updateDesign(state.designId, patches);
        mutateDesign();
        message.success("Successfully updated.");
      } catch (e) {
        message.error("Failed to update design.");
      }
    } else {
      try {
        const design = await createDesign({
          name: state.designName,
          magnetTypeId: state.magnetTypeId,
          tubeItems: state.tubeItems,
        });

        message.success("Successfully created.");
        history.push(`/designs/${design.data.id}`);
      } catch (e) {
        message.error("Failed to create design.");
      }
    }
  }

  const { loading: isTubeTypesLoading, tubeTypes } = useTubeTypes();
  const { loading: isTubeDiametersLoading, tubeDiameters } = useTubeDiameters();
  const isCalculatorLoading = isMagnetTypesLoading || isTubeTypesLoading || isTubeDiametersLoading;

  const tubeTypesMap: any = {};
  each(tubeTypes, tubeType => {
    tubeTypesMap[tubeType.id] = tubeType;
  })
  tubeTypesMap['default'] = defaultDecouplerTubeType;

  const tubesAndResult: TubesAndResult = {
    tableData: [],
    result: {
      totalPressureDrop: "0",
      pressureDropSpec: 0,
      totalLength: "0",
      inletFlangeForce: "0",
      outletThrust: "0",
      gasTemperature: "0",
      gasVelocity: "0",
    },
  };

  const tableData: TubeItemTableRow[] = [];

  useEffect(() => {
    if (design) {
      dispatch({
        type: CalculatorActionType.UpdateDesign,
        payload: {
          design,
          mode: mode === 'clone' ? 'clone' : 'edit',
        }
      })
    }
  }, [design, mode])

  const selectedMagnetType = magnetTypes?.find(m => m.id === state.magnetTypeId);
  if (selectedMagnetType && tubeTypes && tubeDiameters) {
    const decouplerData = calculateVibrationDecouplerData(selectedMagnetType, tubeTypesMap);
    tableData.push(decouplerData)

    let prevTemperatureOut = decouplerData.temperatureOut;
    let subTotalPressureDrop = decouplerData.subTotalPressureDrop;
    let totalTubeLength = decouplerData.length;

    // calculate length for special tube type, e.g. 45 degree
    const calculatedTubes = state.tubeItems.map(tubeItem => {
      const tubeType = tubeTypesMap[tubeItem.tubeTypeId];
      if (tubeType.lengthFactor !== null) {
        const calculatedLength = QLAlgorithm.calculateLengthForTubeType(tubeItem.diameterMm, tubeType.lengthFactor);
        return { ...tubeItem, length: calculatedLength };
      } else {
        return tubeItem;
      }
    });

    for (let tube of calculatedTubes) {
      const tubeType = tubeTypesMap[tube.tubeTypeId];
      let tubeLength: number;
      if (tubeType.lengthFactor !== null) {
        tubeLength = QLAlgorithm.calculateLengthForTubeType(tube.diameterMm, tubeType.lengthFactor);
      } else {
        tubeLength = tube.length;
      }

      let guaranteedNumericTubeLength = tubeLength; //parseFloat(tubeLength);
      if (isNaN(guaranteedNumericTubeLength)) {
        guaranteedNumericTubeLength = 0;
      }
      const calResult = QLAlgorithm.calculatePressureDrop(
        selectedMagnetType.massFlowRate,
        prevTemperatureOut,
        tube.diameterMm / 1000,
        guaranteedNumericTubeLength,
        tubeType.ak,
        tubeType.nb
      );

      subTotalPressureDrop += calResult.pressureDrop;
      totalTubeLength += guaranteedNumericTubeLength;
      prevTemperatureOut = calResult.temperatureOut;

      tableData.push({
        tubeType: tubeType,
        diameter: tube.diameter === null ? 'custom' : tube.diameter,
        diameterMm: tube.diameterMm,
        length: tube.length,
        lengthFt: guaranteedNumericTubeLength * QLAlgorithm.QLC_METER_TO_FOOT,
        temperatureOut: calResult.temperatureOut,
        pressureDrop: calResult.pressureDrop,
        subTotalPressureDrop: subTotalPressureDrop,
      });
    }

    const lastTubeDiameterMm = tableData[tableData.length - 1].diameterMm;
    const outletThrust = QLAlgorithm.calculateOutletThrust(selectedMagnetType.massFlowRate, prevTemperatureOut, lastTubeDiameterMm / 1000);

    tubesAndResult.tableData = tableData.map((tube, index) => ({ ...tube, key: index }));
    tubesAndResult.result = {
      totalPressureDrop: subTotalPressureDrop.toFixed(QLAlgorithm.QLC_PRECISION_PRESSURE_DROP),
      pressureDropSpec: selectedMagnetType.pressureDropSpec,
      inletFlangeForce: QLAlgorithm.calculateInletFlangeForce(subTotalPressureDrop, decouplerData.diameter * QLAlgorithm.QLC_INCH_TO_METER).toFixed(1),
      outletThrust: outletThrust.toFixed(1),
      gasTemperature: prevTemperatureOut.toFixed(0),
      gasVelocity: (outletThrust / selectedMagnetType.massFlowRate).toFixed(1),
      totalLength: totalTubeLength.toFixed(2),
    }
  }

  const progressPercent = tubesAndResult.result.pressureDropSpec === 0 ?
    0 :
    parseFloat(tubesAndResult.result.totalPressureDrop) / tubesAndResult.result.pressureDropSpec * 100;
  const progressStatus = progressPercent <= 100 ? 'success' : 'exception';

  const handleAddNewTube = (index: number) => {
    if (selectedMagnetType && tubeTypes) {
      const firstTubeType = tubeTypes[0];
      let newTube: TubeItem = {
        tubeTypeId: firstTubeType.id,
        diameter: 0,
        diameterMm: 0,
        length: 0,
      };

      if (index === 0) {
        if (selectedMagnetType.vibrationDecouplers.length === 0) {
          newTube.diameter = 4;
        } else {
          newTube.diameter = selectedMagnetType.vibrationDecouplers[0].tubeDiameter;
        }
        newTube.diameterMm = newTube.diameter * QLAlgorithm.QLC_INCH_TO_METER * 1000;
      } else {
        newTube.diameter = state.tubeItems[index - 1].diameter;
        newTube.diameterMm = state.tubeItems[index - 1].diameterMm;
      }

      dispatch({
        type: CalculatorActionType.AddTube,
        payload: {
          index,
          tube: newTube
        }
      });
    }
  }

  return (
    <Row>
      <Col xs={{ span: 24 }}>
        <Card>
          <Spin spinning={isCalculatorLoading}>
            <Row>
              <Col span={12} style={{ padding: '20px' }}>
                <MagnetSetting
                  dispatch={dispatch}
                  selectedMagnetType={selectedMagnetType}
                  magnetTypes={magnetTypes}
                  isLoading={isMagnetTypesLoading}
                />
              </Col>
              <Col span={12} style={{ textAlign: 'center', padding: '20px' }}>
                <Progress
                  type="circle"
                  percent={progressPercent}
                  status={progressStatus}
                  format={
                    () => tubesAndResult.result.totalPressureDrop
                  }
                />
              </Col>
            </Row>

            <Divider />

            <div style={{ margin: '40px 0' }}>
              <TubeItemTable
                dispatch={dispatch}
                tubeTypes={tubeTypes}
                tubeDiameters={tubeDiameters}
                data={tubesAndResult.tableData}
                onAddNewTube={handleAddNewTube}
              />
            </div>

            <Divider />

            <Result {...tubesAndResult.result} />

            <Divider />

            <div style={{ marginBottom: 10 }}>
              <Input
                size="large"
                value={state.designName}
                placeholder="Type a name for this design, so that you can find this calculation easier later."
                onChange={e => dispatch({
                  type: CalculatorActionType.ChangeDesignName,
                  payload: {
                    name: e.target.value,
                  }
                })}
              />
            </div>

            <Button
              type="primary"
              size="large"
              onClick={handleSubmitClick}
              style={{ width: '100%' }}
              loading={false}
            >
              Save results
            </Button>

            {id && (
              <div style={{ textAlign: 'center', margin: '10px auto' }}>
                <Link to={`/print/${id}`}>Go to print</Link>
              </div>
            )}
          </Spin>
        </Card>
      </Col>
    </Row>
  );
}

export default withAuthenticationRequired(CreateDesignPage, {
  onRedirecting: () => <BlockLoading />,
});
