//     Quench line algorithm (QLAlgorithm) 1.0.0

//     algorithm by Lai Bi Hui
//     code by Deng Zhi Cheng (superbulldeng@gmail.com)

const QLAlgorithm = {
  P: 101325,
  QLC_INCH_TO_METER: 0.0254,
  QLC_METER_TO_FOOT: 3.28084,
  QLC_PRECISION_PRESSURE_DROP: 2,

  calculateLengthForTubeType: function(diameterMm: number, lengthFactor: number): number {
    return diameterMm / 1000 * lengthFactor * Math.PI;
  },

  // Calculate the inlet flange force.
  calculateInletFlangeForce: function (totalPressureDrop: number, inletDiameter: number): number {
    var inletArea = Math.PI * inletDiameter * inletDiameter / 4;
    return totalPressureDrop * 100 * inletArea;
  },

  // Calculate the outlet thrust.
  calculateOutletThrust: function(mdot: number, tOut: number, outletDiameter: number): number {
    var outletArea = Math.PI * outletDiameter * outletDiameter / 4;
    return mdot * mdot / (rho(QLAlgorithm.P, tOut) * outletArea);
  },

  // Calculate the pressure drop of one tube.
  calculatePressureDrop: function(mdot: number, temperatureIn: number, diameter: number, length: number, ak: number, nb: number) {
    var
      calculationStatusOK = true,
      pin = QLAlgorithm.P;
    
    var dp, padd, pout, pout2, temperatureOut, alpha, coeff = 0.8;
    var i = 0, j = 0, n = 10;
    
    var output = deltap(mdot, pin, temperatureIn, diameter, length, ak, nb);
    pin = output.Inletpressure;
    pout = output.Outletpressure;
    temperatureOut = output.Outlettemperature;
      
    dp = pout-QLAlgorithm.P;
  
    while (dp>10 || dp<-10) {
      padd = pin+10;
  
      var output2 = deltap(mdot, padd, temperatureIn, diameter, length, ak, nb);
      pout2 = output2.Outletpressure;
      alpha = (pout2-pout)/10;
      
      pin = pin+coeff*(QLAlgorithm.P-pout)/alpha;
      
      output = deltap(mdot, pin, temperatureIn, diameter, length, ak, nb);
      pin = output.Inletpressure;
      pout = output.Outletpressure;
      temperatureOut = output.Outlettemperature;
  
      dp = pout-QLAlgorithm.P;
      //printf("delP = %f\n", dp);
      //printf("pout = %f\n", pout);
      i++;
      if (i>n) {
        //printf ("The input data is invalid, please try a new input");
        coeff = 0.6;
        i = 1;
        j++;
      }
  
      if (j>10) {   
        //printf ("j = %d", j);
        //break;
        calculationStatusOK = false;
        break;
      }
    }
    
    var prdrop = (pin-pout) / 100;
  
    return {
      temperatureOut: temperatureOut,
      pressureDrop: prdrop,
      calculationStatusOK: calculationStatusOK
    };
  },
};

//Helium property**************************************************************************************
function rho(p: number, T: number): number {
  var density, R;
  R=8314/4.0026;
  density=p/(R*T);
  return density;
}

function viscosity(p: number, T: number): number {
  var vis;
  vis=(0.0192*p/100000+0.5301)*Math.pow(T,-0.0076*p/100000+0.6332);
  return vis;
}

function Tcon(p: number, T: number): number {
  var thermocond;
  thermocond=(0.0987*p/100000+3.7348)*Math.pow(T,-0.0055*p/100000+0.6457);
  return thermocond;
}

function Hecp(p: number, T: number): number {
  var cp;
  cp=5.1942+(0.4792*p/100000-0.0189)*Math.exp(-0.102*T);
  return cp;
}

function soundspeed (T: number): number {
  var w;
  w=Math.sqrt(1.68*8314*T/4.0026);
  return w;
}

function HTC(mdot: number, Th: number, Tm: number, D: number, p: number): number {
  //void prop(double T,double PkPa,double *viscosity,double *thermcond,double *cp,double *rho,double *gamma,double *Pr, double *w);
  //double rho(double p, double T), viscosity(double p, double T),Tcon(double p, double T),Hecp(double p, double T),soundspeed (double T);
  var anu, area, viscosityh,viscositym, thermcondh, cph, rhoh, Prh, Re, V, h;

  area=3.14159*D*D/4;
  rhoh=rho(p, Th);
  V=mdot/(area*rhoh);
  viscosityh=viscosity(p, Th);
  viscositym=viscosity(p, Tm);
  Re=(V*D*rhoh)/viscosityh*1000000;
  cph=Hecp(p, Th);
  thermcondh=Tcon(p, Th);
  Prh=cph*viscosityh/thermcondh;
  if (Re>10000)
    anu=0.023*Math.pow(Re, 0.8)*Math.pow(Prh,0.4);
  else
    anu=1.86*Math.pow(Re*Prh, 0.33)*Math.pow(viscosityh/viscositym,0.14);
  
  h=anu*thermcondh*0.001/D;
  return h;
}

function deltap(mdot: number, pin: number, temperatureIn: number, diameter: number, length: number, ak: number, nb: number) {
  let vo: number = 0;
  let w: number = 0;
  var deltaL, ps, Ts, Tw,h,area, vis, cp, qconv,ff, dp, Re,density;
  var qtot, deltat,prdrop, edp;
  var i, n, flag;
  
  deltaL=0.1/nb;
  n=Math.floor(length/deltaL+0.999);
  deltaL=length/n;
  ps=pin;
  Ts=temperatureIn;
  Tw=80;
  dp=0;
  prdrop=0;
  area=3.1415926*diameter*diameter/4.0;
  flag=0;
  

  for(i=1; i<=n;i++)
  {
  
    flag=0;
    //prop(Ts, ps, &viscosity,&thermcond,&cp,&rho, &gamma,&Pr, &w);//调用property子函数，根据t和p计算粘度、导热系数、定压比热容、密度、普朗特数和声速
    //       K kPa    uPa.s  W/m-K  J/kg-K kg/m^3   m/s
        
    //test for velocity > sonic
    density=rho(ps, Ts);
    vo=mdot/(density*area);//计算流速
    w=soundspeed(Ts);
    if(vo/w>=1)
    {
      flag=1;
      break;//检查当地声速，如果超音速了，就要将入口压力加上5kPa
    }
    
    
    vis=viscosity(ps,Ts);
    //now to calculate the step heat transfer
    Re=density*diameter*vo/vis*1000000;
    h=HTC(mdot, Ts, Tw, diameter, ps);

    h=h*(1.0+0.4*ak/0.01);//to allow for corrugations

    qconv=h*3.1415926*diameter*deltaL*(Tw-Ts)*nb;//对流换热热流密度[W]

    //end of calculation of convective heat load

    //to calculate the "step" pressure drop
    ff=0.125*Math.pow(Re,-0.32)+0.0014;//光滑管

    ff=ff+1.0/(Math.pow(4*Math.LOG10E*Math.log(2.0/ak)+2.28,2));//粗糙管？？？


    dp=((2.0*ff*deltaL*vo*vo)/diameter)*density*nb;//注意压力的单位都是kPa

    ps=ps-dp;
    prdrop=prdrop+dp;

    if(ps<=98000)
    {
      flag=2;
      break;
    }

    cp=Hecp(ps,Ts);
    //to calculate friction load
    edp=area*dp*vo;//摩擦力对气体所做的功,[W]； 
    qtot=edp+qconv;//total element power[W]
    deltat=qtot/(cp*1000*mdot);
    Ts=Ts+deltat;
  }

  while (flag>=1)
  {
    if (flag===1){
      pin=pin*(vo/w)+1000.0;
      ps=pin;
    }
    if (flag===2){
      pin=pin+2000+0.9*(n-i)*dp;
      ps=pin;
    }
  


    for(i=1; i<=n;i++)
    {
      flag=0;
      density=rho(ps, Ts);
      vo=mdot/(density*area);//计算流速
      w=soundspeed(Ts);
      if(vo/w>=1)
      {
        flag=1;
        break;//检查当地声速，如果超音速了，就要将入口压力加上5kPa
      }
    
    
      vis=viscosity(ps,Ts);
      //now to calculate the step heat transfer
      Re=density*diameter*vo/vis*1000000;
      h=HTC(mdot, Ts, Tw, diameter, ps);
  
      h=h*(1.0+0.4*ak/0.01);//to allow for corrugations
  
      qconv=h*3.1415926*diameter*deltaL*(Tw-Ts)*nb;//对流换热热流密度[W]
  
      //end of calculation of convective heat load

      //to calculate the "step" pressure drop
      ff=0.125*Math.pow(Re,-0.32)+0.0014;//光滑管
  
      ff=ff+1.0/(Math.pow(4*Math.LOG10E*Math.log(2.0/ak)+2.28,2));//粗糙管
  
  
      dp=((2.0*ff*deltaL*vo*vo)/diameter)*density*nb;
  
      ps=ps-dp;
      prdrop=prdrop+dp;

      if(ps<=98000)
      {
        flag=2;
        break;
      }
        
      cp=Hecp(ps, Ts);
      //to calculate friction load
      edp=area*dp*vo;//摩擦力对气体所做的功,[W]；注意dp的单位都是kPa   
      qtot=edp+qconv;//total element power[W]
      deltat=qtot/(cp*1000*mdot);
      Ts=Ts+deltat;
    }
  }
  
  return {
    Outlettemperature: Ts,
    Outletpressure: ps,
    Inletpressure: pin
  };
}

export default QLAlgorithm;
