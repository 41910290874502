import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { Design } from "../../models/Entities";
import fetchWithToken from "../fetchers/fetch-with-token";

export function useDesign(id?: string) {
  const { getAccessTokenSilently } = useAuth0();
  const { data, error, mutate } = useSWR<Design>(
    id ? [`${process.env.REACT_APP_API_BASE_URL}/designs/${id}`, getAccessTokenSilently] : null,
    fetchWithToken,
    {
      revalidateOnFocus: false,
      revalidateOnMount:true,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0
    }
  );

  const loading = !data && !error;
  const loggedOut = error && error.status === 403;

  // const updateDesign = async (design: DesignFormValues) => {
  //   const token = await getAccessTokenSilently();
  //   return await axios.put(`${process.env.REACT_APP_API_BASE_URL}/designs/${design.id}`, design, {
  //     method: 'PUT',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${token}`,
  //     },
  //   });
  // }

  return {
    loading,
    loggedOut,
    design: data,
    mutate,
    // updateDesign,
  };
}