import React from 'react';
import { Button, Table } from 'antd';
import { TubeType } from '../../../models/Entities';
import { ColumnsType } from 'antd/es/table';

interface TubeTypeListProps {
  tubeTypes?: TubeType[];
  isLoading: boolean;
  handleEditTubeType: (tubeType: TubeType) => void;
}

function List({
  tubeTypes,
  isLoading,
  handleEditTubeType
}: TubeTypeListProps) {
  const columns: ColumnsType<TubeType> = [
    {
      title: 'Icon',
      render: (text, record) => <img width="50" src={record.icon} alt={record.name} />
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Roughness (mm)',
      dataIndex: 'ak',
    },
    {
      title: 'Length multiplier',
      dataIndex: 'nb',
    },
    {
      title: 'Length / ( π * Diameter )',
      dataIndex: 'lengthFactor',
    },
    {
      title: 'Order',
      dataIndex: 'ordering',
    },
    {
      title: 'Is public?',
      dataIndex: 'isPublic',
      render: (text, record) => record.isPublic ? "Yes" : "No",
    },
    {
      title: 'action',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (_, record) =>
        <Button
          size="small"
          onClick={() =>
            handleEditTubeType(record)}
        >
          Edit
        </Button>,
    },
  ];



  return (
    <Table<TubeType>
      columns={columns}
      rowKey={record => record.id}
      dataSource={tubeTypes}
      loading={isLoading}
    />
  );
}

export default List;

