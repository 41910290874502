import { Button, Space, Spin } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { DownloadOutlined } from "@ant-design/icons";

const HomeHeader = styled.h1`
  padding: 36px 0 16px;
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 2rem;
  padding-top: 2rem;
  text-align: center;
  line-height: 1.375;
`;

const Homebody = styled.div`
  padding: 16px;
  min-height: 380px;
  text-align: center;
`;

function Home() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  return (
    <div style={{ backgroundColor: "white" }}>
      <HomeHeader>Quench Line Calculator</HomeHeader>
      <Homebody>
        {isLoading && <Spin />}
        {!isAuthenticated && !isLoading && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="primary"
              size="large"
              shape="round"
              onClick={() => loginWithRedirect()}
            >
              Login
            </Button>
          </div>
        )}
        {isAuthenticated && (
          <Space size="large">
            <Link to={"/my-designs"}>
              <Button
                type="primary"
                shape="round"
                size="large"
              >
                My Designs
              </Button>
            </Link>
            <Button
              type="primary"
              ghost
              icon={<DownloadOutlined />}
              shape="round"
              size="large"
              href="/downloads"
            >
              Downloads
            </Button>
          </Space>
        )}
      </Homebody>
    </div>
  );
}

export default Home;
