import React from 'react';
import { Form, Input, Select } from 'antd';
import { MagnetType } from '../../models/Entities';
import { CalculatorAction, CalculatorActionType } from './reducer';

interface MagnetSettingProps {
  magnetTypes?: MagnetType[];
  selectedMagnetType?: MagnetType;
  dispatch: React.Dispatch<CalculatorAction>;
  isLoading: boolean;
}

export default function MagnetSetting({ dispatch, selectedMagnetType, magnetTypes, isLoading }: MagnetSettingProps) {
  
  const handleMagnetTypeChange = (value: string, label: any) => {
    dispatch({
      type: CalculatorActionType.ChangeMagnetType,
      payload: {
        magnetTypeId: value,
      },
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  return (
    <Form>
      <Form.Item
        {...formItemLayout}
        label="Magnet type"
        style={{ 'marginBottom': '10px' }}
      >
        <Select
          value={selectedMagnetType ? selectedMagnetType.id : undefined}
          onChange={handleMagnetTypeChange}
          disabled={isLoading}
        >
          {magnetTypes?.map((type: any) =>
            <Select.Option
              value={type.id}
              key={type.id}
            >
              {type.name}
            </Select.Option>
          )}
        </Select>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        label="Mass flow rate"
        style={{ 'marginBottom': '10px' }}
      >
        <Input
          addonAfter="kg/s"
          value={selectedMagnetType ? selectedMagnetType.massFlowRate : ''}
          disabled />
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        label="Max pressure"
        style={{ 'marginBottom': '10px' }}
      >
        <Input
          addonAfter="mbar"
          value={selectedMagnetType ? selectedMagnetType.pressureDropSpec : ''}
          disabled />
      </Form.Item>
    </Form>
  );
}
