import { Design, TubeItem } from "../../models/Entities";
import update from 'immutability-helper';
import QLAlgorithm from "../../utils/QLAlgorithm";

export enum CalculatorActionType {
  UpdateDesign = 'UpdateDesign',
  ChangeMagnetType = 'ChangeMagnetType',
  AddTube = 'AddTube',
  DeleteTube = 'DeleteTube',
  UpdateTubes = 'UpdateTubes',
  UpdateTubeLength = 'UpdateTubeLength',
  UpdateTubeDiameter = 'UpdateTubeDiameter',
  UpdateTubeDiameterMm = 'UpdateTubeDiameterMm',
  UpdateTubeType = 'UpdateTubeType',
  ChangeDesignName = 'ChangeDesignName',
}

export type CalculatorAction =
  | { type: CalculatorActionType.UpdateDesign; payload: { design: Design, mode: 'clone' | 'edit' } }
  | { type: CalculatorActionType.ChangeMagnetType; payload: { magnetTypeId?: string } }
  | { type: CalculatorActionType.AddTube; payload: { index: number, tube: TubeItem } }
  | { type: CalculatorActionType.DeleteTube; payload: { index: number } }
  | { type: CalculatorActionType.UpdateTubes; payload: any }
  | { type: CalculatorActionType.UpdateTubeLength; payload: { index: number, length: number } }
  | { type: CalculatorActionType.UpdateTubeDiameter; payload: { index: number, diameter: number | null } }
  | { type: CalculatorActionType.UpdateTubeDiameterMm; payload: { index: number, diameterMm: number } }
  | { type: CalculatorActionType.UpdateTubeType; payload: { index: number, tubeTypeId: string } }
  | { type: CalculatorActionType.ChangeDesignName; payload: { name: string } };

export interface CalculatorState {
  magnetTypeId?: string;
  tubeItems: TubeItem[];
  designName: string;
  designId: string | null;
  createdAt: Date | null;
  updatedAt: Date | null;
}

const calculatorReducer = (state: CalculatorState, action: CalculatorAction): CalculatorState => {
  switch (action.type) {
    case CalculatorActionType.UpdateDesign:
      const isClone = action.payload.mode === 'clone';
      return {
        ...state,
        magnetTypeId: action.payload.design.magnetTypeId,
        tubeItems: isClone ? action.payload.design.tubeItems.map(item => ({...item, id: undefined})) : action.payload.design.tubeItems,
        designName: isClone ? '' : action.payload.design.name,
        designId: isClone ? null : action.payload.design.id,
        createdAt: action.payload.design.createdAt,
        updatedAt: action.payload.design.updatedAt,
      };
    case CalculatorActionType.ChangeMagnetType:
      return {
        ...state,
        magnetTypeId: action.payload.magnetTypeId,
      };
    case CalculatorActionType.AddTube:
      return update(state, {
        tubeItems: { $splice: [[action.payload.index, 0, action.payload.tube]] },
      });
    case CalculatorActionType.DeleteTube:
      return update(state, {
        tubeItems: { $splice: [[action.payload.index, 1]] },
      });
    case CalculatorActionType.UpdateTubeDiameter: {
      // update the changed row
      let newState = update(state, {
        tubeItems: {
          [action.payload.index]: {
            diameter: { $set: action.payload.diameter },
            diameterMm: {
              $apply: (oldDiameterMm) => {
                if (action.payload.diameter !== null) {
                  return action.payload.diameter * QLAlgorithm.QLC_INCH_TO_METER * 1000;
                } else {
                  return oldDiameterMm;
                }
              }
            }
          }
        },
      });

      // update next rows if the diameter is smaller than the changed row
      for (let i = action.payload.index + 1; i < newState.tubeItems.length; i++) {
        if (newState.tubeItems[i].diameterMm < newState.tubeItems[i - 1].diameterMm) {
          newState = update(newState, {
            tubeItems: {
              [i]: {
                diameter: { $set: action.payload.diameter },
                diameterMm: { $set: newState.tubeItems[i - 1].diameterMm },
              }
            }
          })
        }
      }

      return newState;
    }

    case CalculatorActionType.UpdateTubeDiameterMm: {
      // update the changed row
      let newState = update(state, {
        tubeItems: {
          [action.payload.index]: {
            diameterMm: { $set: action.payload.diameterMm },
          }
        },
      });

      // update next rows if the diameter is smaller than the changed row
      for (let i = action.payload.index + 1; i < newState.tubeItems.length; i++) {
        if (newState.tubeItems[i].diameterMm < newState.tubeItems[i - 1].diameterMm) {
          newState = update(newState, {
            tubeItems: {
              [i]: {
                diameter: { $set: newState.tubeItems[i - 1].diameter },
                diameterMm: { $set: newState.tubeItems[i - 1].diameterMm },
              }
            }
          })
        }
      }

      return newState;
    }
    // case CalculatorActionType.UpdateTubes:
    // return update(state, {
    //   tubeItems: { $set: action.payload },
    // });
    case CalculatorActionType.UpdateTubeLength:
      return update(state, {
        tubeItems: {
          [action.payload.index]: {
            length: { $set: action.payload.length },
          }
        },
      });
    case CalculatorActionType.UpdateTubeType:
      return update(state, {
        tubeItems: {
          [action.payload.index]: {
            tubeTypeId: { $set: action.payload.tubeTypeId },
          }
        },
      });

    case CalculatorActionType.ChangeDesignName:
      return update(state, {
        designName: { $set: action.payload.name },
      });

    default:
      return state;
  }
};

export default calculatorReducer;
