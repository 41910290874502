import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { UserInfo } from "../../models/Entities";
import fetchWithToken from "../fetchers/fetch-with-token";
import QueryString from "qs";
import axios from "axios";
import { PaginatedItems } from "../../models/Dtos";

export function useUsers(
  pageIndex: number,
  pageSize: number,
  magnetTypeId: string | undefined,
  searchName: string | undefined) {
  const { getAccessTokenSilently } = useAuth0();

  const queryParams = {
    pageIndex,
    pageSize,
    magnetTypeId,
    searchName,
  }

  const queryString = QueryString.stringify(queryParams);

  const { data, mutate, error } = useSWR<PaginatedItems<UserInfo>>([`${process.env.REACT_APP_API_BASE_URL}/users?${queryString}`, getAccessTokenSilently], fetchWithToken);

  const loading = !data && !error;
  const loggedOut = error && error.status === 403;

  const updateUser = async (userId: string, patches: any) => {
    const token = await getAccessTokenSilently();
    return await axios.patch(
      `${process.env.REACT_APP_API_BASE_URL}/users/${userId}`,
      patches,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
  }

  const deleteUser = async (userId: string) => {
    const token = await getAccessTokenSilently();
    return await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/users/${userId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
  }

  return {
    loading,
    loggedOut,
    paginatedUsers: data,
    mutate,
    updateUser,
    deleteUser,
  };
}