import React from 'react';
import {
  Button,
  InputNumber,
  Select,
  Table,
  TableColumnsType,
} from 'antd';
import { CalculatorActionType } from './reducer';
import QLAlgorithm from '../../utils/QLAlgorithm';
import isNumber from 'lodash/isNumber';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { TubeItemTableProps, TubeItemTableRow } from './models';

function TubeItemTable({ dispatch, tubeTypes, tubeDiameters, data, onAddNewTube }: TubeItemTableProps) {

  const columns: TableColumnsType<TubeItemTableRow> = [
    {
      title: 'Type',
      dataIndex: 'tubeType.id',
      render: (text, record, index) => {
        return index === 0 ? (
          'Vibration decoupler'
        ) : (
          <Select
            value={record.tubeType.id}
            style={{ width: 170 }}
            onChange={(value, label) =>
              dispatch({ type: CalculatorActionType.UpdateTubeType, payload: { index: index - 1, tubeTypeId: value } })
            }
          >
            {tubeTypes?.map((tubeType) => (
              <Select.Option value={tubeType.id} key={tubeType.id}>
                <img height="20" src={tubeType.icon} alt="" /> {tubeType.name}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: 'Diameter (in)',
      dataIndex: 'diameter',
      render: (text, record, index) => {
        return index === 0 ? (
          record.diameter
        ) : (
          <Select
            value={record.diameter}
            style={{ width: 100 }}
            onChange={(value, label) =>
              dispatch({
                type: CalculatorActionType.UpdateTubeDiameter,
                payload: {
                  index: index - 1,
                  diameter: value === 'custom' ? null : value
                }
              })
            }
          >
            {/* only allow user to select diameter that bigger than previous tube */}
            {tubeDiameters?.filter(dia => {
              const tube = data[index - 1];
              if (tube.diameter !== 'custom') {
                return dia.value >= tube.diameter;
              } else {
                return (
                  dia.value * QLAlgorithm.QLC_INCH_TO_METER * 1000 >=
                  tube.diameterMm
                );
              }
            })
              .map((diameter) => (
                <Select.Option value={diameter.value} key={diameter.id}>
                  {diameter.value}
                </Select.Option>
              ))}
            <Select.Option value={'custom'} key={0}>
              custom
            </Select.Option>
          </Select>
        );
      },
    },
    {
      title: 'Diameter (mm)',
      dataIndex: 'diameterMm',
      render: (text, record, index) => {
        return record.diameter === 'custom' ? (
          <InputNumber<number>
            value={
              isNumber(record.diameterMm)
                ? parseFloat(record.diameterMm.toFixed(0))
                : parseFloat(record.diameterMm)
            }
            onChange={(value) =>
              dispatch({
                type: CalculatorActionType.UpdateTubeDiameterMm,
                payload: {
                  index: index - 1,
                  diameterMm: value,
                }
              })
            }
            min={data[index - 1].diameterMm}
            max={1000}
          />
        ) : (
          record.diameterMm.toFixed(0)
        );
      },
    },
    {
      title: 'Length (m)',
      dataIndex: 'length',
      render: (text, record, index) => {
        if (index === 0) {
          return record.length.toFixed(2);
        } else {
          if (record.tubeType.lengthFactor !== null) {
            return record.length.toFixed(2);
          } else {
            return (
              <InputNumber
                value={record.length}
                onChange={value =>
                  dispatch({
                    type: CalculatorActionType.UpdateTubeLength,
                    payload: {
                      index: index - 1,
                      length: value,
                    }
                  })
                }
                min={0}
                max={50}
                step={0.01}
              />
            );
          }
        }
      },
    },
    {
      title: 'Length (ft)',
      dataIndex: 'lengthFt',
      render: (text, record, index) => {
        return record.lengthFt.toFixed(2);
      },
    },
    {
      title: 'Pressure Drop (mbar)',
      dataIndex: 'pressureDrop',
      render: (text, record, index) => {
        return record.pressureDrop.toFixed(
          QLAlgorithm.QLC_PRECISION_PRESSURE_DROP
        );
      },
    },
    {
      title: 'Total Pressure (mbar)',
      dataIndex: 'subTotalPressureDrop',
      render: (text, record, index) => {
        return record.subTotalPressureDrop.toFixed(
          QLAlgorithm.QLC_PRECISION_PRESSURE_DROP
        );
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (text, record, index) => {
        return (
          <Button.Group size="small">
            <Button
              onClick={() => onAddNewTube(index)}
              icon={<PlusOutlined />}
              size="small"
            />
            {index > 0 && (
              <Button
                onClick={() =>
                  dispatch({ type: CalculatorActionType.DeleteTube, payload: { index: index - 1 } })
                }
                icon={<DeleteOutlined />}
                size="small"
              />
            )}
          </Button.Group>
        );
      },
    },
  ];

  return (
    <Table<TubeItemTableRow>
      columns={columns}
      dataSource={data}
      pagination={false}
      loading={false}
    />
  );
}

export default TubeItemTable;
