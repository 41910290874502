import { useEffect, useState } from "react";
import { Modal, Input, Form, Button, InputNumber, Switch, message } from "antd";
import { MagnetTypeFormValues } from "../../../models/Dtos";
import { AxiosResponse } from "axios";
import { MagnetType } from "../../../models/Entities";

interface FormMagnetTypeProps {
  onCreateMagnetType: (values: MagnetTypeFormValues) => Promise<AxiosResponse<MagnetType, any>>;
  onUpdateMagnetType: (values: MagnetTypeFormValues) => Promise<AxiosResponse<any, any>>;
  onClose: () => void;
  visible: boolean;
  isNew: boolean;
  initialValues: MagnetTypeFormValues;
}

const FormMagnetType = ({
  onCreateMagnetType,
  onUpdateMagnetType,
  onClose = () => { },
  visible = false,
  isNew = true,
  initialValues,
}: FormMagnetTypeProps) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
    
  const onFinish = async (values: MagnetTypeFormValues) => {
    setIsSubmitting(true);
    try {
      if (isNew === true) {
        await onCreateMagnetType(values);
        message.success('created');
        onClose();
      } else {
        await onUpdateMagnetType(values);
        message.success('updated');
        onClose();
      }
    } catch (e) {
      console.log(e);
      message.error('Failed');
    } finally {
      setIsSubmitting(false);
    }
  }

  const onFinishFailed = () => {

  }

  const handleOk = () => {
    form.submit();
  }

  useEffect(() => {
    if (visible) {
      form.resetFields();
      form.setFieldsValue(initialValues);
    }
  }, [visible, initialValues, form]);
console.log("initial", initialValues)
  return (
    <Modal
      forceRender
      maskClosable={false}
      visible={visible}
      title={isNew ? `Create Region` : `Edit`}
      onCancel={onClose}
      footer={[
        <Button
          key="cancel"
          disabled={isSubmitting}
          onClick={onClose}
        >
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          loading={isSubmitting}
          onClick={handleOk}
        >
          Save
        </Button>,
      ]}
    >
      <Form
        name="formMagnetType"
        initialValues={initialValues}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Form.Item
          name="id"
          label="Id"
          hidden
        >
          <Input hidden />
        </Form.Item>

        <Form.Item
          name="name"
          label="Name"
          rules={[
            { required: true, message: 'Please fill in the name' },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          name="massFlowRate"
          label="Mass flow rate"
        >
          <InputNumber addonAfter="kg/s" />
        </Form.Item>

        <Form.Item
          name="pressureDropSpec"
          label="Max pressure"
        >
          <InputNumber addonAfter="mbar" />
        </Form.Item>

        <Form.Item
          name="ordering"
          label="Order"
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          name="isPublic"
          label="Is public"
          valuePropName="checked"
        >
          <Switch checkedChildren="Public" unCheckedChildren="Admin only" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FormMagnetType;
