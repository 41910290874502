import { each } from "lodash";
import { MagnetType, VibrationDecoupler } from "../../models/Entities";
import QLAlgorithm from "../../utils/QLAlgorithm";

export function calculateVibrationDecouplerData(magnetType: MagnetType, tubeTypes: any) {
  let subTotalPressureDrop = 0;
  let vibrationDecouplerPressureDrop = 0;
  let vibrationDecouplerTubeLength = 0;
  let prevTemperatureOut = 12;

  // if magnet type has no vibration decouplers, add a default one with length 0
  const defaultVibrationDecoupler: VibrationDecoupler = {
    id: "dummy",
    magnetTypeId: magnetType.id,
    tubeTypeId: "default",
    tubeDiameter: 4,
    tubeLength: 0,
    ordering: 0,
  };
  const decouplers = magnetType.vibrationDecouplers.length === 0 ?
    [defaultVibrationDecoupler] :
    magnetType.vibrationDecouplers;

  each(decouplers, function (decoupler) {
    var decouplerType = tubeTypes[decoupler.tubeTypeId];
    var calResult = QLAlgorithm.calculatePressureDrop(
      magnetType?.massFlowRate,
      prevTemperatureOut,
      decoupler.tubeDiameter * QLAlgorithm.QLC_INCH_TO_METER,
      decoupler.tubeLength,
      decouplerType.ak,
      decouplerType.nb
    );
    vibrationDecouplerPressureDrop += calResult.pressureDrop;
    vibrationDecouplerTubeLength += decoupler.tubeLength;
    prevTemperatureOut = calResult.temperatureOut;
  });
  subTotalPressureDrop += vibrationDecouplerPressureDrop;

  const decouplerDiameter = decouplers[0].tubeDiameter;

  return {
    tubeType: {
      id: 'default',
      name: 'Vibration Decoupler',
      ak: 0.01,
      nb: 1,
      lengthFactor: null,
      icon: '',
      ordering: 0,
      isPublic: true,
    },
    diameter: decouplerDiameter,
    diameterMm: decouplerDiameter * QLAlgorithm.QLC_INCH_TO_METER * 1000,
    length: vibrationDecouplerTubeLength,
    lengthFt: vibrationDecouplerTubeLength * QLAlgorithm.QLC_METER_TO_FOOT,
    temperatureOut: prevTemperatureOut,
    pressureDrop: vibrationDecouplerPressureDrop,
    subTotalPressureDrop: subTotalPressureDrop,
  };
}