import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { DesignFormValues } from "../../models/Dtos";
import { Design } from "../../models/Entities";

export function useDesignApi() {
  const { getAccessTokenSilently } = useAuth0();

  const createDesign = async (design: DesignFormValues) => {
    const token = await getAccessTokenSilently();

    return await axios.post<Design>(
      `${process.env.REACT_APP_API_BASE_URL}/designs`,
      {
        ...design,
        tubeItems: design.tubeItems.map((item, index) => ({...item, ordering: index})),
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
  }

  const updateDesign = async (designId: string, patches: any) => {
    const token = await getAccessTokenSilently();
    return await axios.patch(
      `${process.env.REACT_APP_API_BASE_URL}/designs/${designId}`,
      // {
      //   ...design,
      //   tubeItems: design.tubeItems.map((item, index) => ({...item, ordering: index})),
      // },
      patches,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
  }

  return {
    createDesign,
    updateDesign,
  };
}