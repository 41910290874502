import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import useSWR from "swr";
import { TubeDiameterFormValues } from "../../models/Dtos";
import { TubeDiameter } from "../../models/Entities";
import fetchWithToken from "../fetchers/fetch-with-token";

export function useTubeDiameters() {
  const { getAccessTokenSilently } = useAuth0();
  const { data, mutate, error } = useSWR([`${process.env.REACT_APP_API_BASE_URL}/tube-diameters`, getAccessTokenSilently], fetchWithToken);

  const loading = !data && !error;
  const loggedOut = error && error.status === 403;

  const createTubeDiameter = async (tubeDiameter: TubeDiameterFormValues) => {
    const token = await getAccessTokenSilently();

    return await axios.post<TubeDiameter>(`${process.env.REACT_APP_API_BASE_URL}/tube-diameters`, tubeDiameter, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
  }

  const updateTubeDiameter = async (tubeDiameter: TubeDiameterFormValues) => {
    const token = await getAccessTokenSilently();
    return await axios.put(`${process.env.REACT_APP_API_BASE_URL}/tube-diameters/${tubeDiameter.id}`, tubeDiameter, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
  }

  return {
    loading,
    loggedOut,
    tubeDiameters: data,
    mutate,
    createTubeDiameter,
    updateTubeDiameter,
  };
}