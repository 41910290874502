import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { RoleOption } from "../../models/Dtos";
import fetchWithToken from "../fetchers/fetch-with-token";

export function useRoles() {
  const { getAccessTokenSilently } = useAuth0();
  const { data, mutate, error } = useSWR<RoleOption[]>([`${process.env.REACT_APP_API_BASE_URL}/roles`, getAccessTokenSilently], fetchWithToken);

  const loading = !data && !error;
  const loggedOut = error && error.status === 403;

  return {
    loading,
    loggedOut,
    roles: data,
    mutate,
  };
}
