import { useState } from 'react';
import { useUsers } from '../../../lib/hooks/use-users';
import { Button, Input, List, message, Popconfirm, Select, Space, Table, TableColumnsType, TablePaginationConfig, Tag } from 'antd';
import { Design, MagnetType, UserInfo } from '../../../models/Entities';
import { UserFormValues } from '../../../models/Dtos';
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useMagnetTypes } from '../../../lib/hooks/use-magnet-types';
import { useDesigns } from '../../../lib/hooks/use-designs';
import { Link } from 'react-router-dom';
import { PaginationConfig } from 'antd/lib/pagination';

function getMagnetTypeName(magnetTypeId?: string, magnetTypes?: MagnetType[]) {
  if (magnetTypeId === undefined || magnetTypes === undefined || magnetTypes.length === 0) return "--";

  const magnetType = magnetTypes.find(item => item.id === magnetTypeId);
  if (magnetType === undefined) return "--";

  return magnetType.name;
}

function DesignPage() {
  const { loading: isMagnetTypeLoading, magnetTypes } = useMagnetTypes();
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [magnetTypeId, setMagnetTypeId] = useState<string | undefined>(undefined);
  const [searchName, setSearchName] = useState<string | undefined>(undefined);
  const {
    loading: isDesignsLoading,
    paginatedDesigns,
    deleteDesign,
    mutate: mutateMyDesigns
  } = useDesigns(pageIndex, pageSize, magnetTypeId, searchName);

  const header = <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <div>
      <Select
        placeholder="Select a magnet type"
        value={magnetTypeId}
        onChange={value => setMagnetTypeId(value)}
        disabled={isMagnetTypeLoading}
        style={{ width: 200, marginRight: '5px' }}
        allowClear
      >
        {magnetTypes?.map(type =>
          <Select.Option
            value={type.id}
            key={type.id}
          >
            {type.name}
          </Select.Option>
        )}
      </Select>

      <Input.Search
        placeholder="search by name"
        onSearch={
          value => setSearchName(value.trim() === '' ? undefined : value.trim())
        }
        style={{ width: 200, marginRight: '5px' }}
      />

    </div>
    <div>
      <Link to="/calculator">
        <Button type="primary">Create Design</Button>
      </Link>
    </div>
  </div>;

  const paginator: PaginationConfig | undefined = paginatedDesigns ? {
    pageSize: paginatedDesigns.pageSize,
    current: paginatedDesigns.pageIndex,
    total: paginatedDesigns.count,
    onChange: ((page, pageSize) => {
      setPageIndex(page);
      setPageSize(pageSize);
    }),
  } : undefined;

  async function handleDeleteDesign(id: string) {
    try {
      await deleteDesign(id);
      mutateMyDesigns();
      message.success("Delete successfully.");
    } catch (e) {
      message.error("Failed to delete.");
      console.log(e)
    }
  }

  return (
    <>
      <List<Design>
            header={header}
            itemLayout="horizontal"
            dataSource={paginatedDesigns?.items}
            pagination={paginator}
            loading={isDesignsLoading}
            renderItem={item => {
              const actions = [
                <Link to={`/designs/${item.id}`}>
                  <Button
                    title='Edit'
                    type="link"
                    icon={<EditOutlined />}
                    size="small"
                  />
                </Link>,
                <Link to={`/designs/${item.id}/clone`}>
                  <Button
                    title='Clone'
                    type="link"
                    icon={<CopyOutlined />}
                    size="small"
                  />
                </Link>,
                <Popconfirm
                  title="Are you sure delete this design?"
                  onConfirm={() => handleDeleteDesign(item.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    title='Delete'
                    type="link"
                    icon={<DeleteOutlined />}
                    size="small"
                  />
                </Popconfirm>,
              ];
              return (
                <List.Item
                  key={item.id}
                  actions={actions}
                >
                  <List.Item.Meta
                    title={item.name}
                    description={`${getMagnetTypeName(item.magnetTypeId, magnetTypes)}, by ${item.authorGid}, ${item.createdAt}`}
                  />
                </List.Item>
              );
            }}
          />
    </>
  );
}

export default DesignPage;
