import { List, Spin } from "antd";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import styled from "styled-components";
import { DownloadOutlined } from "@ant-design/icons";
import { BlockLoading } from "../../components/Loading";

const HomeHeader = styled.h1`
  padding: 36px 0 16px;
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 2rem;
  padding-top: 2rem;
  text-align: center;
  line-height: 1.375;
`;

const Downloadsbody = styled.div`
  padding: 16px;
  min-height: 380px;
`;

const data = [
  {
    title: "Quench line acceptance protocol (Checklist)",
    url: "/Quench_line_acceptance_protocol_checklist.xlsm",
  },
  {
    title: "Quench line design document",
    url: "/Quench_line_design_document.PDF",
  },
  {
    title: "Angled rain hood drawings 6 inch",
    url: "/Angled_rain_hood_drawings_6_inch.zip",
  },
  {
    title: "Angled rain hood drawings 8 inch",
    url: "/Angled_rain_hood_drawings_8_inch.zip",
  },
  {
    title: "Angled rain hood drawings 10 inch",
    url: "/Angled_rain_hood_drawings_10_inch.zip",
  },
];

function DownloadsPage() {
  const { isLoading } = useAuth0();

  return (
    <div style={{ backgroundColor: "white" }}>
      <HomeHeader>Downloads</HomeHeader>
      <Downloadsbody>
        {isLoading && <Spin />}
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<DownloadOutlined />}
                title={<a href={item.url}>{item.title}</a>}
              />
            </List.Item>
          )}
        />
      </Downloadsbody>
    </div>
  );
}

export default withAuthenticationRequired(DownloadsPage, {
  onRedirecting: () => <BlockLoading />,
});
