import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import useSWR from "swr";
import { TubeTypeFormValues } from "../../models/Dtos";
import { TubeType } from "../../models/Entities";
import fetchWithToken from "../fetchers/fetch-with-token";

export function useTubeTypes() {
  const { getAccessTokenSilently } = useAuth0();
  const { data, mutate, error } = useSWR<TubeType[]>([`${process.env.REACT_APP_API_BASE_URL}/tube-types`, getAccessTokenSilently], fetchWithToken);

  const loading = !data && !error;
  const loggedOut = error && error.status === 403;

  const createTubeType = async (tubeType: TubeTypeFormValues) => {
    const token = await getAccessTokenSilently();

    return await axios.post<TubeType>(`${process.env.REACT_APP_API_BASE_URL}/tube-types`, tubeType, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
  }

  const updateTubeType = async (tubeType: TubeTypeFormValues) => {
    const token = await getAccessTokenSilently();
    return await axios.put(`${process.env.REACT_APP_API_BASE_URL}/tube-types/${tubeType.id}`, tubeType, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
  }

  return {
    loading,
    loggedOut,
    tubeTypes: data,
    mutate,
    createTubeType,
    updateTubeType,
  };
}