import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import useSWR from "swr";
import { MagnetTypeFormValues } from "../../models/Dtos";
import { MagnetType } from "../../models/Entities";
import fetchWithToken from "../fetchers/fetch-with-token";

export function useMagnetTypes() {
  const { getAccessTokenSilently } = useAuth0();
  const { data, mutate, error } = useSWR<MagnetType[]>([`${process.env.REACT_APP_API_BASE_URL}/magnet-types`, getAccessTokenSilently], fetchWithToken);

  const loading = !data && !error;
  const loggedOut = error && error.status === 403;

  const createMagnetType = async (magnetType: MagnetTypeFormValues) => {
    const token = await getAccessTokenSilently();

    return await axios.post<MagnetType>(`${process.env.REACT_APP_API_BASE_URL}/magnet-types`, magnetType, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
  }

  const updateMagnetType = async (magnetType: MagnetTypeFormValues) => {
    const token = await getAccessTokenSilently();
    return await axios.put(`${process.env.REACT_APP_API_BASE_URL}/magnet-types/${magnetType.id}`, magnetType, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
  }

  return {
    loading,
    loggedOut,
    magnetTypes: data,
    mutate,
    createMagnetType,
    updateMagnetType,
  };
}