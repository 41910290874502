import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import useSWR from "swr";
import { VibrationDecouplerFormValues } from "../../models/Dtos";
import { MagnetType, VibrationDecoupler } from "../../models/Entities";
import fetchWithToken from "../fetchers/fetch-with-token";

export function useMagnetType(id: string) {
  const { getAccessTokenSilently } = useAuth0();
  const { data, mutate, error } = useSWR<MagnetType>([`${process.env.REACT_APP_API_BASE_URL}/magnet-types/${id}`, getAccessTokenSilently], fetchWithToken);

  const loading = !data && !error;
  const loggedOut = error && error.status === 403;

  const createVibrationDecoupler = async (magnetType: VibrationDecouplerFormValues) => {
    const token = await getAccessTokenSilently();

    return await axios.post<VibrationDecoupler>(`${process.env.REACT_APP_API_BASE_URL}/vibration-decouplers`, magnetType, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
  }

  const updateVibrationDecoupler = async (magnetType: VibrationDecouplerFormValues) => {
    const token = await getAccessTokenSilently();
    return await axios.put(`${process.env.REACT_APP_API_BASE_URL}/vibration-decouplers/${magnetType.id}`, magnetType, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
  }

  return {
    loading,
    loggedOut,
    magnetType: data,
    mutate,
    createVibrationDecoupler,
    updateVibrationDecoupler,
  };
}