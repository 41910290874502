import {
  Table,
  TableColumnsType,
} from 'antd';
import QLAlgorithm from '../../utils/QLAlgorithm';
import { TubeItemTableProps, TubeItemTableRow } from './models';

function TubeItemTablePrint({ dispatch, tubeTypes, tubeDiameters, data, onAddNewTube }: TubeItemTableProps) {

  const columns: TableColumnsType<TubeItemTableRow> = [
    {
      title: 'Type',
      width: 139,
      dataIndex: 'tubeType.id',
      render: (text, record, index) => {
        return index === 0 ? (
          'Vibration decoupler'
        ) : (
          <div>
            <img height="20" src={record.tubeType.icon} alt="" /> {record.tubeType.name}
          </div>
        );
      },
    },
    {
      title: 'Diameter (in)',
      dataIndex: 'diameter',
    },
    {
      title: 'Diameter (mm)',
      dataIndex: 'diameterMm',
      render: (text, record, index) => record.diameterMm.toFixed(0),
    },
    {
      title: 'Length (m)',
      dataIndex: 'length',
      render: (text, record, index) => record.length.toFixed(2),
    },
    {
      title: 'Length (ft)',
      dataIndex: 'lengthFt',
      render: (text, record, index) => {
        return record.lengthFt.toFixed(2);
      },
    },
    {
      title: 'Pressure Drop (mbar)',
      dataIndex: 'pressureDrop',
      render: (text, record, index) => {
        return record.pressureDrop.toFixed(
          QLAlgorithm.QLC_PRECISION_PRESSURE_DROP
        );
      },
    },
    {
      title: 'Total Pressure (mbar)',
      dataIndex: 'subTotalPressureDrop',
      render: (text, record, index) => {
        return record.subTotalPressureDrop.toFixed(
          QLAlgorithm.QLC_PRECISION_PRESSURE_DROP
        );
      },
    },
  ];

  return (
    <Table<TubeItemTableRow>
      size='small'
      columns={columns}
      dataSource={data}
      pagination={false}
      loading={false}
    />
  );
}

export default TubeItemTablePrint;
