import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { Design } from "../../models/Entities";
import fetchWithToken from "../fetchers/fetch-with-token";
import QueryString from "qs";
import axios from "axios";
import { PaginatedItems } from "../../models/Dtos";

export function useMyDesigns(
  pageIndex: number,
  pageSize: number,
  magnetTypeId: string | undefined,
  searchName: string | undefined)
{
  const { getAccessTokenSilently } = useAuth0();

  const queryParams = {
    pageIndex,
    pageSize,
    magnetTypeId,
    searchName,
  }

  const queryString = QueryString.stringify(queryParams);

  const { data, mutate, error } = useSWR<PaginatedItems<Design>>([`${process.env.REACT_APP_API_BASE_URL}/my-designs?${queryString}`, getAccessTokenSilently], fetchWithToken);

  const loading = !data && !error;
  const loggedOut = error && error.status === 403;

  const deleteDesign = async (designId: string) => {
    const token = await getAccessTokenSilently();
    return await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/designs/${designId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
  }

  return {
    loading,
    loggedOut,
    paginatedDesigns: data,
    mutate,
    deleteDesign,
  };
}