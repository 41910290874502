import { Button, Space, Table, TableColumnsType } from 'antd';
import { MagnetType } from '../../../models/Entities';
import { Link } from 'react-router-dom';

interface MagnetTypeListProps {
  magnetTypes?: MagnetType[];
  isLoading: boolean;
  handleEditMagnetType: (magnetType: MagnetType) => void;
}

function List({
  magnetTypes,
  isLoading,
  handleEditMagnetType
}: MagnetTypeListProps) {
  const columns: TableColumnsType<MagnetType> = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Mass flow rate (kg/s)',
      dataIndex: 'massFlowRate',
    },
    {
      title: 'Max pressure (mbar)',
      dataIndex: 'pressureDropSpec',
    },
    {
      title: 'Order',
      dataIndex: 'ordering',
    },
    {
      title: 'Is public?',
      dataIndex: 'isPublic',
      render: (text, record) => record.isPublic ? "Yes" : "No",
    },
    {
      title: 'action',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (_, record) =>
        <Space size="middle">
          <Button
            size="small"
            onClick={() =>
              handleEditMagnetType(record)}
          >
            Edit
          </Button>
          <Link to={`/admin/magnet-type/${record.id}/vibration-decoupler`}>
            <Button
              size="small"
            >
              Vibration decouplers
            </Button>
          </Link>
        </Space>,
    },
  ];

  return (
    <Table<MagnetType>
      columns={columns}
      rowKey={record => record.id}
      dataSource={magnetTypes}
      loading={isLoading}
    />
  );
}

export default List;

