import { useState } from 'react';
import { Button } from 'antd';
import FormTubeDiameter from './Form';
import { useTubeDiameters } from '../../../lib/hooks/use-tube-diameters';
import { TubeDiameter } from '../../../models/Entities';
import List from './List';
import { TubeDiameterFormValues } from '../../../models/Dtos';


function TubeDiameterPage() {
  const { loading, tubeDiameters, createTubeDiameter, updateTubeDiameter, mutate } = useTubeDiameters();

  // Form TubeDiameter
  const [isTubeDiameterFormNew, setIsTubeDiameterFormNew] = useState(false);
  const [formTubeDiameterModalVisible, setFormTubeDiameterModalVisible] = useState(false);
  const [formTubeDiameterInitialValues, setFormTubeDiameterInitialValues] = useState<TubeDiameterFormValues>({
    isPublic: false,
    value: 0,
  });

  const addTubeDiameter = () => {
    setIsTubeDiameterFormNew(true);
    setFormTubeDiameterInitialValues({
      isPublic: false,
      value: 0,
    })
    setFormTubeDiameterModalVisible(true);
  }
  const handleEditTubeDiameter = (record: TubeDiameter): void => {
    const initialValues = { ...record };
    setFormTubeDiameterInitialValues(initialValues)
    setIsTubeDiameterFormNew(false);
    setFormTubeDiameterModalVisible(true);
  }
  const handleTubeDiameterModalClose = () => {
    setFormTubeDiameterModalVisible(false);
    mutate();
  }

  return (
    <>
      <div style={{marginBottom: '16px'}}>
        <Button type="primary" onClick={addTubeDiameter} >
          Add tubeDiameter
        </Button>
      </div>
      <List
        tubeDiameters={tubeDiameters}
        isLoading={loading}
        handleEditTubeDiameter={handleEditTubeDiameter}
      />
      <FormTubeDiameter
        visible={formTubeDiameterModalVisible}
        onClose={handleTubeDiameterModalClose}
        isNew={isTubeDiameterFormNew}
        initialValues={formTubeDiameterInitialValues}
        onCreateTubeDiameter={createTubeDiameter}
        onUpdateTubeDiameter={updateTubeDiameter}
      />
    </>
  );
}

export default TubeDiameterPage;

