import { useEffect, useState } from "react";
import { Modal, Input, Form, Button, InputNumber, Switch, message } from "antd";
import { TubeDiameterFormValues } from "../../../models/Dtos";
import { AxiosResponse } from "axios";
import { TubeDiameter } from "../../../models/Entities";

interface FormTubeDiameterProps {
  onCreateTubeDiameter: (values: TubeDiameterFormValues) => Promise<AxiosResponse<TubeDiameter, any>>;
  onUpdateTubeDiameter: (values: TubeDiameterFormValues) => Promise<AxiosResponse<any, any>>;
  onClose: () => void;
  visible: boolean;
  isNew: boolean;
  initialValues: TubeDiameterFormValues;
}

const TubeTypeForm = ({
  onCreateTubeDiameter,
  onUpdateTubeDiameter,
  onClose = () => { },
  visible = false,
  isNew = true,
  initialValues,
}: FormTubeDiameterProps) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
    
  const onFinish = async (values: TubeDiameterFormValues) => {
    setIsSubmitting(true);
    try {
      if (isNew === true) {
        await onCreateTubeDiameter(values);
        message.success('created');
        onClose();
      } else {
        await onUpdateTubeDiameter(values);
        message.success('updated');
        onClose();
      }
    } catch (e) {
      console.log(e);
      message.error('Failed');
    } finally {
      setIsSubmitting(false);
    }
  }

  const onFinishFailed = () => {

  }

  const handleOk = () => {
    form.submit();
  }

  useEffect(() => {
    if (visible) {
      form.resetFields();
      form.setFieldsValue(initialValues);
    }
  }, [visible, initialValues, form]);
console.log("initial", initialValues)
  return (
    <Modal
      forceRender
      maskClosable={false}
      visible={visible}
      title={isNew ? `Create Region` : `Edit`}
      onCancel={onClose}
      footer={[
        <Button
          key="cancel"
          disabled={isSubmitting}
          onClick={onClose}
        >
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          loading={isSubmitting}
          onClick={handleOk}
        >
          Save
        </Button>,
      ]}
    >
      <Form
        name="formTubeDiameter"
        initialValues={initialValues}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Form.Item
          name="id"
          label="Id"
          hidden
        >
          <Input hidden />
        </Form.Item>

        <Form.Item
          name="value"
          label="Diameter"
        >
          <InputNumber addonAfter="inch" />
        </Form.Item>

        <Form.Item
          name="isPublic"
          label="Is public"
          valuePropName="checked"
        >
          <Switch checkedChildren="Public" unCheckedChildren="Admin only" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TubeTypeForm;
