import { useEffect, useReducer } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import {
  Button,
  Col,
  Divider,
  Row,
  Spin,
  Statistic,
} from 'antd';
import reducer, { CalculatorActionType, CalculatorState } from './reducer';
import QLAlgorithm from '../../utils/QLAlgorithm';
import { useParams } from 'react-router-dom';
import { useTubeTypes } from '../../lib/hooks/use-tube-types';
import { each } from 'lodash';
import { useTubeDiameters } from '../../lib/hooks/use-tube-diameters';
import { useDesign } from '../../lib/hooks/use-design';
import { useMagnetTypes } from '../../lib/hooks/use-magnet-types';
import { defaultDecouplerTubeType, TubeItemTableRow, TubesAndResult } from './models';
import TubeItemTablePrint from './TubeItemTablePrint';
import { calculateVibrationDecouplerData } from './calculator-helper';

function PrintPage() {
  const { loading: isMagnetTypesLoading, magnetTypes } = useMagnetTypes();
  const initialState: CalculatorState = {
    magnetTypeId: undefined,
    tubeItems: [],
    designName: '',
    designId: null,
    createdAt: null,
    updatedAt: null,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const { id, mode } = useParams<{ id?: string, mode?: string }>();
  const { design } = useDesign(id);

  const { loading: isTubeTypesLoading, tubeTypes } = useTubeTypes();
  const { loading: isTubeDiametersLoading, tubeDiameters } = useTubeDiameters();
  const isCalculatorLoading = isMagnetTypesLoading || isTubeTypesLoading || isTubeDiametersLoading;

  const tubeTypesMap: any = {};
  each(tubeTypes, tubeType => {
    tubeTypesMap[tubeType.id] = tubeType;
  })
  tubeTypesMap['default'] = defaultDecouplerTubeType;

  const tubesAndResult: TubesAndResult = {
    tableData: [],
    result: {
      totalPressureDrop: "0",
      pressureDropSpec: 0,
      totalLength: "0",
      inletFlangeForce: "0",
      outletThrust: "0",
      gasTemperature: "0",
      gasVelocity: "0",
    },
  };

  const tableData: TubeItemTableRow[] = [];

  useEffect(() => {
    if (design) {
      dispatch({
        type: CalculatorActionType.UpdateDesign,
        payload: {
          design,
          mode: mode === 'clone' ? 'clone' : 'edit',
        }
      })
    }
  }, [design, mode])

  const selectedMagnetType = magnetTypes?.find(m => m.id === state.magnetTypeId);
  if (selectedMagnetType && tubeTypes && tubeDiameters) {
    const decouplerData = calculateVibrationDecouplerData(selectedMagnetType, tubeTypesMap);
    tableData.push(decouplerData)

    let prevTemperatureOut = decouplerData.temperatureOut;
    let subTotalPressureDrop = decouplerData.subTotalPressureDrop;
    let totalTubeLength = decouplerData.length;

    // calculate length for special tube type, e.g. 45 degree
    const calculatedTubes = state.tubeItems.map(tubeItem => {
      const tubeType = tubeTypesMap[tubeItem.tubeTypeId];
      if (tubeType.lengthFactor !== null) {
        const calculatedLength = QLAlgorithm.calculateLengthForTubeType(tubeItem.diameterMm, tubeType.lengthFactor);
        return { ...tubeItem, length: calculatedLength };
      } else {
        return tubeItem;
      }
    });

    for (let tube of calculatedTubes) {
      const tubeType = tubeTypesMap[tube.tubeTypeId];
      let tubeLength: number;
      if (tubeType.lengthFactor !== null) {
        tubeLength = QLAlgorithm.calculateLengthForTubeType(tube.diameterMm, tubeType.lengthFactor);
      } else {
        tubeLength = tube.length;
      }

      let guaranteedNumericTubeLength = tubeLength; //parseFloat(tubeLength);
      if (isNaN(guaranteedNumericTubeLength)) {
        guaranteedNumericTubeLength = 0;
      }
      const calResult = QLAlgorithm.calculatePressureDrop(
        selectedMagnetType.massFlowRate,
        prevTemperatureOut,
        tube.diameterMm / 1000,
        guaranteedNumericTubeLength,
        tubeType.ak,
        tubeType.nb
      );

      subTotalPressureDrop += calResult.pressureDrop;
      totalTubeLength += guaranteedNumericTubeLength;
      prevTemperatureOut = calResult.temperatureOut;

      tableData.push({
        tubeType: tubeType,
        diameter: tube.diameter === null ? 'custom' : tube.diameter,
        diameterMm: tube.diameterMm,
        length: tube.length,
        lengthFt: guaranteedNumericTubeLength * QLAlgorithm.QLC_METER_TO_FOOT,
        temperatureOut: calResult.temperatureOut,
        pressureDrop: calResult.pressureDrop,
        subTotalPressureDrop: subTotalPressureDrop,
      });
    }

    const lastTubeDiameterMm = tableData[tableData.length - 1].diameterMm;
    const outletThrust = QLAlgorithm.calculateOutletThrust(selectedMagnetType.massFlowRate, prevTemperatureOut, lastTubeDiameterMm / 1000);

    tubesAndResult.tableData = tableData.map((tube, index) => ({ ...tube, key: index }));
    tubesAndResult.result = {
      totalPressureDrop: subTotalPressureDrop.toFixed(QLAlgorithm.QLC_PRECISION_PRESSURE_DROP),
      pressureDropSpec: selectedMagnetType.pressureDropSpec,
      inletFlangeForce: QLAlgorithm.calculateInletFlangeForce(subTotalPressureDrop, decouplerData.diameter * QLAlgorithm.QLC_INCH_TO_METER).toFixed(1),
      outletThrust: outletThrust.toFixed(1),
      gasTemperature: prevTemperatureOut.toFixed(0),
      gasVelocity: (outletThrust / selectedMagnetType.massFlowRate).toFixed(1),
      totalLength: totalTubeLength.toFixed(2),
    }
  }

  const progressPercent = tubesAndResult.result.pressureDropSpec === 0 ?
    0 :
    parseFloat(tubesAndResult.result.totalPressureDrop) / tubesAndResult.result.pressureDropSpec * 100;
  // const progressStatus = progressPercent <= 100 ? 'success' : 'exception';

  const passOrFail = progressPercent <= 100 ?
    <Statistic valueStyle={{ color: '#21ba45', fontSize: "3rem" }} value="PASS" /> :
    <Statistic valueStyle={{ color: '#db2828', fontSize: "3rem" }} value="FAIL" />;
  
  const pressureDropStyle = progressPercent <= 100 ?
    { color: '#21ba45' } :
    { color: '#db2828' };

  return (
    <div style={{ background: "white" }}>
      <div style={{ maxWidth: "960px", margin: "auto", padding: "10px" }}>
        <Spin spinning={isCalculatorLoading}>
          <h1 style={{ textAlign: 'center', marginBottom: '20px', fontSize: "2rem" }}>Quench Line Calculator Report</h1>
          <h4 style={{ textAlign: 'center', marginBottom: '50px', color: 'rgb(0,0,0,0.65)' }}>{design?.name}</h4>
          <Row>
            <Col span={12}>
              <table className="print-table">
                <tbody>
                  <tr>
                    <th>Magnet Type:</th>
                    <td>{selectedMagnetType && selectedMagnetType.name}</td>
                  </tr>
                  <tr>
                    <th>Mass flow rate:</th>
                    <td>{selectedMagnetType && selectedMagnetType.massFlowRate}</td>
                  </tr>
                  <tr>
                    <th>Max pressure:</th>
                    <td>{selectedMagnetType && selectedMagnetType.pressureDropSpec}</td>
                  </tr>
                </tbody>
              </table>
              <div style={{ textAlign: "center", padding: "10px" }}>
                {passOrFail}
              </div>
            </Col>
            <Col span={12}>
              <table className="print-table">
                <tbody>
                  <tr>
                    <th style={pressureDropStyle}>Total pressure drop:</th>
                    <td style={pressureDropStyle}>{tubesAndResult.result.totalPressureDrop}</td>
                  </tr>
                  <tr>
                    <th>Total length:</th>
                    <td>{tubesAndResult.result.totalLength}</td>
                  </tr>
                  <tr>
                    <th>Inlet flange force:</th>
                    <td>{tubesAndResult.result.inletFlangeForce}</td>
                  </tr>
                  <tr>
                    <th>Outlet thrust:</th>
                    <td>{tubesAndResult.result.outletThrust}</td>
                  </tr>
                  <tr>
                    <th>Gas temperature:</th>
                    <td>{tubesAndResult.result.gasTemperature}</td>
                  </tr>
                  <tr>
                    <th>Gas velocity:</th>
                    <td>{tubesAndResult.result.gasVelocity}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <Divider />
          <div style={{ margin: '40px 0' }}>
            <TubeItemTablePrint
              dispatch={dispatch}
              tubeTypes={tubeTypes}
              tubeDiameters={tubeDiameters}
              data={tubesAndResult.tableData}
              onAddNewTube={() => { }}
            />
          </div>

          <div>Calculated at: {design?.updatedAt}</div>

          <div className="no-print" style={{ marginTop: '20px' }}>
            <Button
              type="primary"
              size="large"
              onClick={() => window.print()}
              style={{ width: '100%' }}
            >
              Print
            </Button>
          </div>
        </Spin>
      </div>
    </div>
  );
}

export default withAuthenticationRequired(PrintPage, {
  onRedirecting: () => <div>loading</div>,
});
