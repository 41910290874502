import { TubeDiameter, TubeType } from "../../models/Entities";
import { CalculatorAction } from "./reducer";

export interface TubeItemTableProps {
  dispatch: React.Dispatch<CalculatorAction>;
  tubeTypes?: TubeType[];
  tubeDiameters?: TubeDiameter[];
  data: TubeItemTableRow[];
  onAddNewTube: (index: number) => void;
}

export interface TubeItemTableRow {
  tubeType: TubeType;
  diameter: number | 'custom';
  diameterMm: number;
  length: number;
  lengthFt: number;
  temperatureOut: number;
  pressureDrop: number;
  subTotalPressureDrop: number;
}

export interface CalculationResultProps {
  totalPressureDrop: string;
  pressureDropSpec: number;
  inletFlangeForce: string;
  outletThrust: string;
  gasTemperature: string;
  gasVelocity: string;
  totalLength: string;
}

export interface TubesAndResult {
  tableData: TubeItemTableRow[];
  result: CalculationResultProps;
}

export const defaultDecouplerTubeType: TubeType = {
  id: 'default',
  name: 'Vibration Decoupler',
  ak: 0.01,
  nb: 1,
  lengthFactor: null,
  icon: '',
  ordering: 0,
  isPublic: true,
}