import { Button, Table } from 'antd';
import { TubeType, VibrationDecoupler } from '../../../models/Entities';
import { ColumnsType } from 'antd/es/table';

interface VibrationDecouplerListProps {
  vibrationDecouplers?: VibrationDecoupler[];
  isLoading: boolean;
  handleEditVibrationDecoupler: (vibrationDecoupler: VibrationDecoupler) => void;
  tubeTypes?: TubeType[];
}

function List({
  vibrationDecouplers,
  isLoading,
  handleEditVibrationDecoupler,
  tubeTypes
}: VibrationDecouplerListProps) {

  const columns: ColumnsType<VibrationDecoupler> = [
    {
      title: 'Tube type',
      render: (text, record) => {
        const tubeType = tubeTypes?.find(x => x.id === record.tubeTypeId);
        return (
          <div>
            <img height="20" src={tubeType?.icon} alt={tubeType?.name} />
            {tubeType?.name}
          </div>
        );
      }
    },
    {
      title: 'Diameter (in)',
      dataIndex: 'tubeDiameter',
    },
    {
      title: 'Length (m)',
      dataIndex: 'tubeLength',
    },
    {
      title: 'Order',
      dataIndex: 'ordering',
    },
    {
      title: 'action',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (_, record) =>
        <Button
          size="small"
          onClick={() =>
            handleEditVibrationDecoupler(record)}
        >
          Edit
        </Button>,
    },
  ];



  return (
    <Table<VibrationDecoupler>
      columns={columns}
      rowKey={record => record.id}
      dataSource={vibrationDecouplers}
      loading={isLoading}
    />
  );
}

export default List;

