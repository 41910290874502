import { useState } from 'react';
import { useUsers } from '../../../lib/hooks/use-users';
import { Button, message, Popconfirm, Space, Table, TableColumnsType, TablePaginationConfig, Tag } from 'antd';
import { UserInfo } from '../../../models/Entities';
import FormUser from './Form';
import { UserFormValues } from '../../../models/Dtos';
import { DeleteOutlined } from '@ant-design/icons';

function UserPage() {
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [magnetTypeId, setUserId] = useState<string | undefined>(undefined);
  const [searchName, setSearchName] = useState<string | undefined>(undefined);
  const {
    loading: isUsersLoading,
    paginatedUsers,
    updateUser,
    deleteUser,
    mutate: mutateUsers
  } = useUsers(pageIndex, pageSize, magnetTypeId, searchName);

  const pagination: TablePaginationConfig | undefined = paginatedUsers ? {
    pageSize: paginatedUsers.pageSize,
    current: paginatedUsers.pageIndex,
    total: paginatedUsers.count,
    onChange: ((page, pageSize) => {
      setPageIndex(page);
      setPageSize(pageSize);
    }),
  } : undefined;

  function handleTableChange() {

  }

  // Form User
  const [isUserFormNew, setIsUserFormNew] = useState(false);
  const [formUserModalVisible, setFormUserModalVisible] = useState(false);
  const [formUserInitialValues, setFormUserInitialValues] = useState<UserFormValues>({
    roles: []
  });
  const handleEditUser = (record: UserInfo): void => {
    const initialValues = { ...record };
    setFormUserInitialValues(initialValues)
    setIsUserFormNew(false);
    setFormUserModalVisible(true);
  }
  const handleUserModalClose = () => {
    setFormUserModalVisible(false);
    mutateUsers();
  }

  async function handleDeleteUser(user: UserInfo) {
    try {
      await deleteUser(user.id);
      mutateUsers();
      message.success("Delete successfully.");
    } catch (e) {
      message.error("Failed to delete.");
      console.log(e)
    }
  }

  const columns: TableColumnsType<UserInfo> = [
    {
      title: 'Gid',
      dataIndex: 'gid',
    },
    {
      title: 'Family name',
      dataIndex: 'familyName',
    },
    {
      title: 'Given name',
      dataIndex: 'givenName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Email verified?',
      dataIndex: 'emailVerified',
      render: (text, record) => record.emailVerified ? "Yes" : "No",
    },
    {
      title: "Roles",
      dataIndex: 'roles',
      render: (roles: string[]) => (
        <>
          {roles.map(role => (
            <Tag color="blue" key={role}>
              {role}
            </Tag>
          ))}
        </>
      )
    },
    {
      title: 'action',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (_, record) =>
        <Space size="middle">
          <Button
            size="small"
            onClick={() => handleEditUser(record)}
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this user?"
            onConfirm={() => handleDeleteUser(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              title='Delete'
              danger
              size="small"
            >
              Delete
            </Button>
          </Popconfirm>
        </Space>,
    },
  ];

  return (
    <>
      <Table<UserInfo>
        columns={columns}
        rowKey={record => record.id}
        dataSource={paginatedUsers?.items}
        loading={isUsersLoading}
        pagination={pagination}
        onChange={handleTableChange}
      />
      <FormUser
        visible={formUserModalVisible}
        onClose={handleUserModalClose}
        isNew={isUserFormNew}
        initialValues={formUserInitialValues}
        onUpdateUser={updateUser}
      />
    </>
  );
}

export default UserPage;
