import { Fragment, useState } from 'react';
import { Button } from 'antd';
import FormVibrationDecoupler from './Form';
import { VibrationDecoupler } from '../../../models/Entities';
import List from './List';
import { VibrationDecouplerFormValues } from '../../../models/Dtos';
import { useParams } from 'react-router-dom';
import { useMagnetType } from '../../../lib/hooks/use-magnet-type';
import { useTubeTypes } from '../../../lib/hooks/use-tube-types';


function VibrationDecouplerPage() {
  const { id } = useParams<{id: string}>();
  const { magnetType, mutate, loading: isMagnetTypeLoading, createVibrationDecoupler, updateVibrationDecoupler } = useMagnetType(id);
  const { loading: isTubeTypesLoading, tubeTypes } = useTubeTypes();

  const isLoading = isMagnetTypeLoading || isTubeTypesLoading;

  // Form VibrationDecoupler
  const [isVibrationDecouplerFormNew, setIsVibrationDecouplerFormNew] = useState(false);
  const [formVibrationDecouplerModalVisible, setFormVibrationDecouplerModalVisible] = useState(false);
  const [formVibrationDecouplerInitialValues, setFormVibrationDecouplerInitialValues] = useState<VibrationDecouplerFormValues>({
    magnetTypeId: id,
    ordering: 0,
  });

  const addVibrationDecoupler = () => {
    setIsVibrationDecouplerFormNew(true);
    setFormVibrationDecouplerInitialValues({
      magnetTypeId: id,
      ordering: 0,
    })
    setFormVibrationDecouplerModalVisible(true);
  }
  const handleEditVibrationDecoupler = (record: VibrationDecoupler): void => {
    const initialValues = { ...record };
    setFormVibrationDecouplerInitialValues(initialValues)
    setIsVibrationDecouplerFormNew(false);
    setFormVibrationDecouplerModalVisible(true);
  }
  const handleVibrationDecouplerModalClose = () => {
    setFormVibrationDecouplerModalVisible(false);
    mutate();
  }

  return (
    <Fragment>
      <h1>Vibration decouplers of { magnetType?.name }</h1>
      <div style={{marginBottom: '16px'}}>
        <Button type="primary" onClick={addVibrationDecoupler} >
          Add
        </Button>
      </div>
      <List
        vibrationDecouplers={magnetType?.vibrationDecouplers}
        isLoading={isLoading}
        handleEditVibrationDecoupler={handleEditVibrationDecoupler}
        tubeTypes={tubeTypes}
      />
      <FormVibrationDecoupler
        visible={formVibrationDecouplerModalVisible}
        onClose={handleVibrationDecouplerModalClose}
        isNew={isVibrationDecouplerFormNew}
        initialValues={formVibrationDecouplerInitialValues}
        onCreateVibrationDecoupler={createVibrationDecoupler}
        onUpdateVibrationDecoupler={updateVibrationDecoupler}
        tubeTypes={tubeTypes}
      />
    </Fragment>
  );
}

export default VibrationDecouplerPage;

