import { useEffect, useState } from "react";
import { Modal, Input, Form, Select, Button, message } from "antd";
import { UserFormValues } from "../../../models/Dtos";
import { AxiosResponse } from "axios";
import { useRoles } from "../../../lib/hooks/use-roles";

interface FormUserProps {
  onUpdateUser: (userId: string, patches: any) => Promise<AxiosResponse<any, any>>;
  onClose: () => void;
  visible: boolean;
  isNew: boolean;
  initialValues: UserFormValues;
}

const FormUser = ({
  onUpdateUser,
  onClose = () => { },
  visible = false,
  isNew = true,
  initialValues,
}: FormUserProps) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
    
  const { roles } = useRoles();

  const onFinish = async (values: UserFormValues) => {
    setIsSubmitting(true);
    try {
      if (isNew === true) {
        // await onCreateUser(values);
        // message.success('created');
        // onClose();
      } else {
        if (values.id) {
          const patches = [];
          patches.push({
            "op": "replace",
            "path": "/Roles",
            "value": values.roles,
          });
          await onUpdateUser(values.id, patches);
          message.success('updated');
          onClose();
        }
      }
    } catch (e) {
      console.log(e);
      message.error('Failed');
    } finally {
      setIsSubmitting(false);
    }
  }

  const onFinishFailed = () => {

  }

  const handleOk = () => {
    form.submit();
  }

  useEffect(() => {
    if (visible) {
      form.resetFields();
      form.setFieldsValue(initialValues);
    }
  }, [visible, initialValues, form]);

  return (
    <Modal
      forceRender
      maskClosable={false}
      visible={visible}
      title={isNew ? `Create Region` : `Edit`}
      onCancel={onClose}
      footer={[
        <Button
          key="cancel"
          disabled={isSubmitting}
          onClick={onClose}
        >
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          loading={isSubmitting}
          onClick={handleOk}
        >
          Save
        </Button>,
      ]}
    >
      <Form
        name="formUser"
        initialValues={initialValues}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Form.Item
          name="id"
          label="Id"
          hidden
        >
          <Input hidden />
        </Form.Item>

        <Form.Item
          name="familyName"
          label="Family name"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="givenName"
          label="Given name"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="roles"
          label="Roles"
          
        >
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Please select"
          >
            {roles?.map(role => {
              return (
                <Select.Option
                  key={role.key}
                  value={role.key}
                  disabled={role.key === 'Qlc:Admin'}
                >
                  {role.value}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FormUser;
