import { useEffect, useState } from "react";
import { Modal, Input, Form, Select, Button, InputNumber, message } from "antd";
import { VibrationDecouplerFormValues } from "../../../models/Dtos";
import { AxiosResponse } from "axios";
import { TubeType, VibrationDecoupler } from "../../../models/Entities";

interface FormVibrationDecouplerProps {
  onCreateVibrationDecoupler: (values: VibrationDecouplerFormValues) => Promise<AxiosResponse<VibrationDecoupler, any>>;
  onUpdateVibrationDecoupler: (values: VibrationDecouplerFormValues) => Promise<AxiosResponse<any, any>>;
  onClose: () => void;
  visible: boolean;
  isNew: boolean;
  initialValues: VibrationDecouplerFormValues;
  tubeTypes?: TubeType[];
}

const FormVibrationDecoupler = ({
  onCreateVibrationDecoupler,
  onUpdateVibrationDecoupler,
  onClose = () => { },
  visible = false,
  isNew = true,
  initialValues,
  tubeTypes,
}: FormVibrationDecouplerProps) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onFinish = async (values: VibrationDecouplerFormValues) => {
    setIsSubmitting(true);
    try {
      if (isNew === true) {
        await onCreateVibrationDecoupler(values);
        message.success('created');
        onClose();
      } else {
        await onUpdateVibrationDecoupler(values);
        message.success('updated');
        onClose();
      }
    } catch (e) {
      console.log(e);
      message.error('Failed');
    } finally {
      setIsSubmitting(false);
    }
  }

  const onFinishFailed = () => {

  }

  const handleOk = () => {
    form.submit();
  }

  useEffect(() => {
    if (visible) {
      form.resetFields();
      form.setFieldsValue(initialValues);
    }
  }, [visible, initialValues, form]);

  return (
    <Modal
      forceRender
      maskClosable={false}
      visible={visible}
      title={isNew ? `Create vibration decoupler` : `Edit`}
      onCancel={onClose}
      footer={[
        <Button
          key="cancel"
          disabled={isSubmitting}
          onClick={onClose}
        >
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          loading={isSubmitting}
          onClick={handleOk}
        >
          Save
        </Button>,
      ]}
    >
      <Form
        name="formVibrationDecoupler"
        initialValues={initialValues}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Form.Item
          name="id"
          label="Id"
          hidden
        >
          <Input hidden />
        </Form.Item>

        <Form.Item
          name="magnetTypeId"
          label="MagnetTypeId"
          hidden
        >
          <Input hidden />
        </Form.Item>

        <Form.Item
          name="tubeTypeId"
          label="Tube Type"
          rules={[
            { required: true, message: 'Please select tube type' },
          ]}
        >
          <Select
            style={{ width: 170 }}
          >
            {tubeTypes?.map(tubeType =>
              <Select.Option
                value={tubeType.id}
                key={tubeType.id}
              >
                <img height="20" src={tubeType.icon} alt="" /> {tubeType.name}
              </Select.Option>
            )}
          </Select>
        </Form.Item>

        <Form.Item
          name="tubeDiameter"
          label="Diameter"
          rules={[
            { required: true, message: 'Please fill in the diameter' },
          ]}
        >
          <InputNumber addonAfter="in" />
        </Form.Item>

        <Form.Item
          name="tubeLength"
          label="Length"
          rules={[
            { required: true, message: 'Please fill in the length' },
          ]}
        >
          <InputNumber addonAfter="m" />
        </Form.Item>

        <Form.Item
          name="ordering"
          label="Order"
          rules={[
            { required: true, message: 'Please fill in the diameter' },
          ]}
        >
          <InputNumber />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FormVibrationDecoupler;
