import { withAuthenticationRequired } from '@auth0/auth0-react';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import UserPage from './User';
import MagnetTypePage from './MagnetType';
import { useUser } from '../../lib/hooks/use-user';
import TubeDiameterPage from './TubeDiameter';
import TubeTypePage from './TubeType';
import VibrationDecouplerPage from './VibrationDecoupler';
import { Layout, Menu, MenuProps } from 'antd';
import { BlockLoading } from '../../components/Loading';
import DesignPage from './Design';
const { Sider, Content } = Layout;

function AdminPage() {
  const { path } = useRouteMatch();
  const location = useLocation();

  const history = useHistory();

  const { loading, user: userInfo } = useUser();

  if (!loading && userInfo && !userInfo.roles.includes("Qlc:Admin")) {
    return <div>Access denied.</div>
  }

  const selectedKeys = [location.pathname];

  const menuItems: MenuProps['items'] = [
    {
      type: 'group',
      label: 'User',
      key: 'user',
      children: [
        { label: <Link to="/admin/user">All Users</Link>, key: '/admin/user' }
      ],
    },
    {
      type: 'group',
      label: 'Design',
      key: 'design',
      children: [
        { label: <Link to="/admin/design">All Designs</Link>, key: '/admin/design' }
      ],
    },
    {
      type: 'group',
      label: 'Calculator',
      key: 'calculator',
      children: [
        {
          label: (
            <Link to="/admin/magnet-type">Magnet Type</Link>
          ),
          key: '/admin/magnet-type',
        },
        {
          label: (
            <Link to="/admin/tube-type">Tube Type</Link>
          ),
          key: '/admin/tube-type',
        },
        {
          label: (
            <Link to="/admin/tube-diameter">Tube Diameter</Link>
          ),
          key: '/admin/tube-diameter',
        },
      ],
    },
    
  ];
  
  return (
    <>
      <Layout>
        <Sider style={{ background: 'white' }}>
          <Menu
            selectedKeys={selectedKeys}
            mode='inline'
            items={menuItems}
          />
        </Sider>
        <Content style={{ background: 'white' }}>
          <div style={{ padding: "16px" }}>
            <Switch>
              <Route path={`${path}/user`} component={UserPage} />
              <Route path={`${path}/design`} component={DesignPage} />
              <Route path={`${path}/magnet-type/:id/vibration-decoupler`} component={VibrationDecouplerPage} />
              <Route path={`${path}/magnet-type`} component={MagnetTypePage} />
              <Route path={`${path}/tube-type`} component={TubeTypePage} />
              <Route path={`${path}/tube-diameter`} component={TubeDiameterPage} />
            </Switch>
          </div>
        </Content>
      </Layout>
    </>
  );
}

export default withAuthenticationRequired(AdminPage, {
  onRedirecting: () => <BlockLoading />,
});
