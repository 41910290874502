import { Col, Form, Row, Statistic } from 'antd';
import { CalculationResultProps } from './models';

export default function Result(props: CalculationResultProps) {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const passOrFail =
    parseFloat(props.totalPressureDrop) <= props.pressureDropSpec ? (
      <Statistic
        value="PASS"
        valueStyle={{ color: '#21ba45', fontSize: '4rem' }}
      />
    ) : (
      <Statistic
        value="FAIL"
        valueStyle={{ color: '#cf1322', fontSize: '4rem' }}
      />
    );

  return (
    <Form>
      <Row>
        <Col span={12}>
          <Form.Item
            {...formItemLayout}
            label="Total pressure drop"
            style={{ marginBottom: '10px' }}
          >
            <div className="result-data">{props.totalPressureDrop} mbar</div>
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            label="Total length"
            style={{ marginBottom: '10px' }}
          >
            <div className="result-data">{props.totalLength} m</div>
          </Form.Item>

          <div style={{ textAlign: 'center', padding: '10px' }}>
            {passOrFail}
          </div>
        </Col>
        <Col span={12}>
          <Form.Item
            {...formItemLayout}
            label="Inlet flange force"
            style={{ marginBottom: '10px' }}
          >
            <div className="result-data">{props.inletFlangeForce} N</div>
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            label="Outlet thrust"
            style={{ marginBottom: '10px' }}
          >
            <div className="result-data">{props.outletThrust} N</div>
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            label="Gas temperature"
            style={{ marginBottom: '10px' }}
          >
            <div className="result-data">{props.gasTemperature} K</div>
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            label="Gas velocity"
            style={{ marginBottom: '10px' }}
          >
            <div className="result-data">{props.gasVelocity} m/s</div>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
