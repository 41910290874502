import { Button, Table } from 'antd';
import { TubeDiameter } from '../../../models/Entities';
import { ColumnsType } from 'antd/es/table';

interface TubeDiameterListProps {
  tubeDiameters: TubeDiameter[];
  isLoading: boolean;
  handleEditTubeDiameter: (tubeDiameter: TubeDiameter) => void;
}

function List({
  tubeDiameters,
  isLoading,
  handleEditTubeDiameter
}: TubeDiameterListProps) {
  const columns: ColumnsType<TubeDiameter> = [
    {
      title: 'Diameter (inch)',
      dataIndex: 'value',
    },
    {
      title: 'Is public?',
      dataIndex: 'isPublic',
      render: (text, record) => record.isPublic ? "Yes" : "No",
    },
    {
      title: 'action',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (_, record) =>
        <Button
          size="small"
          onClick={() =>
            handleEditTubeDiameter(record)}
        >
          Edit
        </Button>,
    },
  ];

  return (
    <Table<TubeDiameter>
      columns={columns}
      rowKey={record => record.id}
      dataSource={tubeDiameters}
      loading={isLoading}
    />
  );
}

export default List;

