import { Auth0ProviderOptions } from '@auth0/auth0-react';
import {
  Auth0Client,
  Auth0ClientOptions,
  LogoutOptions,
  LogoutUrlOptions,
} from '@auth0/auth0-spa-js';
import { useSpaSettings } from './useSpaSettings';

const toAuth0ClientOptions = (
  opts: Auth0ProviderOptions
): Auth0ClientOptions => {
  const { clientId, redirectUri, maxAge, ...validOpts } = opts;
  return {
    ...validOpts,
    client_id: clientId,
    redirect_uri: redirectUri,
    max_age: maxAge,
    auth0Client: {
      name: 'auth0-react',
      version: '1.9.0',
    },
  };
};

export function useSiemensAuth0Logout() {
  const { data } = useSpaSettings();

  const audience =
    data?.healthineersIdAudience && data?.healthineersIdAudience !== "YOUR_API_IDENTIFIER"
      ? data?.healthineersIdAudience
      : null;

  const clientOptions = {
    domain: data?.healthineersIdDomain ? data?.healthineersIdDomain : "",
    clientId: data?.healthineersIdClientId ? data?.healthineersIdClientId : "",
    ...(audience ? { audience: audience } : null),
    redirectUri: data?.healthineersIdRedirectUri ? data?.healthineersIdRedirectUri : "",
    useRefreshTokens: true,
  };

  class SiemensHealthneersAuth0Client extends Auth0Client {
    public buildLogoutUrl(options: LogoutUrlOptions = {}): string {
      const encodedReturnUrl = encodeURIComponent(options.returnTo!);
      return `https://${data?.healthineersIdParentDomain}/v2/logout?federated&returnTo=https%3A%2F%2F${data?.healthineersIdDomain}/v2/logout%3FreturnTo%3D${encodedReturnUrl}%26client_id%3D${clientOptions.clientId}%26auth0Client%3DeyJuYW1lIjoiYXV0aDAtcmVhY3QiLCJ2ZXJzaW9uIjoiMS45LjAifQ%3D%3D`;
    }
  }

  const singleSignOut = (options: LogoutOptions = {}): Promise<void> | void => {
    const siemensClient = new SiemensHealthneersAuth0Client(toAuth0ClientOptions(clientOptions));
    return siemensClient.logout(options);
  }

  return {
    singleSignOut,
  }
}
